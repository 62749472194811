/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~plyr/dist/plyr.css";

@import '~@fortawesome/fontawesome-free/css/all.css';

/*fonts*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&display=swap');

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url('./assets/fonts/Poppins-Light.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('./assets/fonts/Poppins-Medium.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-weight: normal;
    src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Comfortaa';
    font-weight: 300;
    src: url('./assets/fonts/Comfortaa-Light.ttf');
}
@font-face {
    font-family: 'Comfortaa';
    font-weight: 500;
    src: url('./assets/fonts/Comfortaa-Medium.ttf');
}
@font-face {
    font-family: 'Comfortaa';
    font-weight: 600;
    src: url('./assets/fonts/Comfortaa-SemiBold.ttf');
}
@font-face {
    font-family: 'Comfortaa';
    font-weight: 700;
    src: url('./assets/fonts/Comfortaa-Bold.ttf');
}
@font-face {
    font-family: 'Comfortaa';
    font-weight: normal;
    src: url('./assets/fonts/Comfortaa-Regular.ttf');
}

canvas, svg{
    max-width: 100% !important;
}

.money, .amount{
    font-family: 'Poppins';
}

.cursor, .pointer, .cursor-pointer{
    cursor: pointer;
}

.no-click{
    pointer-events: none;
}

.alertMeassage {
    font-size: 12px;
}

st-editor{
    .st-toolbar, .st-area{
        .st-toolbar-item{
            background-color: var(--background, var(--ion-background-color)) !important;
            &.active{
                background-color: darken($color: #008EA3, $amount: 12)  !important;
            }
        }
        background-color: var(--background, var(--ion-background-color)) !important;
    }
}

.tox{
    &.tox-tinymce {
        max-width: 100% !important;
    }
    .tox-tinymce-aux {
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
    }
}
.mce-tooltip {
    position: fixed !important;
    }
    .mce-panel.mce-floatpanel.mce-menu {
    position: fixed !important;
    }

// root variables
$border-radius: 10px;
$logo-width: 120px;

$white: #ffffff;
$black: #000000;
$grey: #6e6e6e;
$light-grey: #e6e6e6;
$light: #f2f2f2;
$dark: #111111;
$danger: #ff6161;
$light-shadow: #ddd;
$dark-shadow: #000;

$font-inter: 'Comfortaa', sans-serif;

//global css
:root {
    --border-radius: 10px;
    --logo-width: 120px;
    --white: #ffffff;
    --grey: #6e6e6e;
    --light-grey: #e6e6e6;
    --black: #000000;
    --light: #f2f2f2;
    --dark: #111111;

    --light-shadow: #ddd;
    --dark-shadow: #222;

    --ion-font-family: 'Comfortaa', sans-serif;
}

.ion-color-admin{
    /** super admin color **/
	--ion-color-base: var(--ion-color-admin);
	--ion-color-base-rgb: var(--ion-color-admin-rgb);
	--ion-color-contrast: var(--ion-color-admin-contrast);
	--ion-color-contrast-rgb: var(--ion-color-admin-contrast-rgb);
	--ion-color-shade: var(--ion-color-admin-shade);
	--ion-color-tint: var(--ion-color-admin-tint);
}

[dir=rtl] {
    ion-label, ion-input {
        text-align: start;
    }
} 
//End global css
$childClass: (
    ion-color-danger: (varcolor: --ion-color-danger),
    ion-color-warning: (varcolor: --ion-color-warning),
    ion-color-primary: (varcolor: --ion-color-primary),
    ion-color-secondary: (varcolor: --ion-color-secondary),
    ion-color-tertiary: (varcolor: --ion-color-tertiary),
    ion-color-dark: (varcolor: --ion-color-dark),
    ion-color-success: (varcolor: --ion-color-success),
    ion-color-admin: (varcolor: --ion-color-admin),
);

ion-range-calendar {
    background: var(--ion-color-light) !important;
    border-radius: 15px !important;
    ion-range-calendar-month {
        button.days-btn{
            // p{
            //     color: var(--ion-color-dark) !important;
            // }
            &[disabled] {
                p{
                    color: rgba(var(--ion-color-dark-rgb), .4) !important;
                }
            }
            &.marked {
                background: rgba(var(--ion-color-base-rgb), 0.25) !important;
                color: var(--ion-color-shade) !important;
                border-radius: 0px !important;
                width: 100% !important;
                position: relative;
                p {
                    color: inherit !important;
                }
            }
        }
        div.days {
            @each $key, $value in $childClass {
                &:has(button.marked.#{$key}) {
                    &::after, &::before {
                        background: var(map-get($value, varcolor)) !important;
                    }
                }
            }
            &.startSelection, &.endSelection, &.between {
                button.marked{
                    background: rgba(var(--ion-color-base-rgb), 1) !important;
                    color: var(--ion-color-contrast) !important;
                    border-radius: 36px !important;
                    p {
                        color: inherit !important;
                    }
                }
            }
            &.between{
                button.marked{
                    border-radius: 0px !important;
                }
            }
        }
    }
}
//color classes
.bg-clear {
    background: none;
    --background: none;
}
.bg-primary-sheep{
    background-color: var(--ion-color-primary);
    --background: var(--ion-color-primary);
}
.bg-primary-church-acc{
    background-color: var(--ion-color-secondary);
    --background: var(--ion-color-secondary);
}
.bg-primary-shepherd{
    background-color: var(--ion-color-tertiary);
    --background: var(--ion-color-tertiary);
}
.bg-primary-super-admin{
    background-color: var(--ion-color-admin);
    --background: var(--ion-color-admin);
}

.bg-white {
    background-color: $white;
    --background: var(--white);
}

ion-modal.popup-news-marketing {
    --max-height: 96%;
    --border-radius: 10px;
    --width: 92%;
    --max-width: 92%;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --height: auto;
    --overflow: scroll;
    .wrapper{
        margin-bottom: 10px;
    }
}

.black-color {
    color: $black;
    --color: var(--black);
}

.grey-color {
    color: $grey;
    --color: var(--grey);
}

.white-color {
    color: $white;
    --color: var(--white);
}

ion-card {
    &:not(.with-shadow) {
        box-shadow: none;
    }
    margin: 5px 16px;

    ion-label {
        width: 100%;
    }
}

// fix for modal content behind the navigation bar of the device
.ion-page ion-footer.has-input{
    bottom: calc(var(--ion-safe-area-bottom, 0) + 1px);
}

ion-toolbar{
    --background: none;
}

ion-button {
    text-transform: none;
}

div.ion-page {
    --border-radius: 20px;
}

.datetime-calendar{
    margin-inline: auto;
    margin-top: 16px;
    border-radius: 8px;
}

a {
    text-decoration: none;
    color: inherit;
}

.header {
    // --background: var(--ion-color-primary);
    --color: var(--black);

    ion-toolbar {
        ion-title {
            --color: var(--black);
            font-weight: 300;
        }

        ion-back-button {
            --color: var(--black);
            font-size: 15px;
        }
    }
}

.datetime-popover {
    --width: max-content;
}

.position-relative{
    position: relative;
}

.position-sticky{
    position: sticky;
    z-index: 4;
    &.top {
        top: 0px;
    }
}

// Plyr css
.plyr.plyr--full-ui.plyr--video{
    // border-radius: 8px;
    margin-bottom: 0px;
}

.profile-header {
    // --background: var(--ion-color-primary);
    --color: var(--black);

    ion-toolbar {
        --background: transparent;

        ion-title {
            --color: var(--black);
            font-weight: 300;
        }

        ion-back-button {
            --color: var(--black);
            font-size: 15px;
        }
    }
}

div.restricted{
    ion-icon {
        font-size: 100px !important;
    }
}

.church-acc-header {
    --background: var(--ion-color-primary);
    --color: var(--white);

    ion-toolbar {
        ion-title {
            --color: var(--white);
            font-weight: 300;
        }

        ion-back-button {
            --color: var(--white);
            font-size: 15px;
        }
    }
}

.menu-profile {
    --min-height: 70px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 20px;
        vertical-align: baseline;
    }

    .bottom {
        position: absolute;
        bottom: 0;
    }

}

.header-segment {
    border-radius: 0px;
    // border-bottom: solid 1pt var(--light-grey);
    padding-top: 10px;

    &::-webkit-scrollbar {
        // width: 0px;
        background: none;
        display: none;
        /* make scrollbar transparent */
    }

    ion-segment-button {
        border-radius: 0px;
        --border-width: 0px;
        --indicator-height: 15%;
        --indicator-transform: translate3d(0, 615%, 0);

        &::part(indicator) {
            width: 60px;
            margin: auto;
        }

        &.expanded {
            min-width: 33.4333% !important;
        }
    }

    .segment-button-checked {
        color: var(--black);
        font-weight: 500;
    }
}

.sheep-segment {
    ion-segment-button {
        --indicator-color: var(--ion-color-primary);
    }
}

.shepherd-segment {
    ion-segment-button {
        --indicator-color: var(--ion-color-tertiary);
    }
}

.acc-segment {
    ion-segment-button {
        --indicator-color: var(--ion-color-secondary);
    }
}

.super-segment {
    ion-segment-button {
        --indicator-color: var(--ion-color-admin);
    }
}

.reaction-segment {
    border-radius: 0px;

    ion-segment-button {
        border-radius: 0px;
        padding-block: 6px;
        --indicator-height: 15%;
        --indicator-transform: translate3d(0, 615%, 0);

        &::part(indicator) {
            width: 60px;
            margin: auto;
        }

        ion-icon{
            font-size: 20px;
        }
    }

    .segment-button-checked {
        color: var(--black);
    }

    .comments {
        min-width: 40%;
    }
}

.category-segment {
    &::-webkit-scrollbar {
        // width: 0px;
        background: transparent;
        display: none;
        /* make scrollbar transparent */
    }
    ion-segment-button {
        border-radius: 10px;
        margin: 0 5px;
        --indicator-color: none;
        min-width: 70%;
        filter: brightness(60%);
    }
    .edit-category {
        min-width: 30%;
        filter: brightness(100%);
    }
    .segment-button-checked {
        color:var(--black);
        font-weight: 500;
        opacity: 0.9;
        filter: brightness(100%);
    }
    padding-bottom: 0px;
}


.contain {
    object-fit: contain;
}

.img-100 {
    width: 100px;
    height: 100px;
}

.img-70 {
    width: 70px;
    height: 70px;
}

.img-30 {
    width: 30px;
    height: 30px;
}

.img-preview {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    &:not(.slidable) {
        overflow:  auto !important;
    }

    .img-item {
        scroll-behavior: smooth;
        margin: 0px 5px;
        flex: 0 0 77%;
        //   background: var(--light);
        background: none;
        width: 90%;
        position: relative;

        img,
        video,
        app-video-player video {
            width: 100%;
            height: 200px;
            object-fit: contain;
        }

        ion-icon {
            position: absolute;
            font-size: 25px;
            display: block;
            top: 8px;
            right: 2.16%;
        }
    }

    .img-item-add {
        width: 100%;
        height: 200px;
        display: flex;
        border: solid 1pt var(--black);
        border-radius: 20px;
        justify-content: center;
        align-items: center;

        ion-icon {
            font-size: 30px;
        }
    }

    // &.img-button{
    //     overflow: auto;
    //     justify-content: end;
    //     margin-top: -66px;
    // }

    // ion-button{
    //     --border-radius: 50%;
    //     margin-left: auto;
    //     width: 50px;
    // }


}
.media app-video-player video {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.swiper-container{
    overflow: unset;
    padding-bottom: 10px;
    .swiper-pagination.swiper-pagination-bullets{
      bottom: -8px;
      .swiper-pagination-bullet{
        background: var(--grey);
        width: 20px;
        border-radius: 8px;
        transition: .6s ease-in-out all;
        &:first-child:last-child {
            display: none;
        }
      }
      .swiper-pagination-bullet-active{
        background: var(--ion-color-base);
        width: 30px;
      }
    }
}
.pad-top-10{
    padding-top: 10px;
}

.container {
    width: 90%;
    margin: auto;
}

.s-scroll {
    height: calc(100% - 360px);
    overflow: scroll;
}

@media screen and (max-height: 710px) {
    .s-scroll {
        height: calc(100% - 260px);
    }
}

@media screen and (max-height: 600px) {
    .s-scroll {
        height: calc(100% - 160px);
    }
}

.page-container {
    margin-top: 50px;
    overflow: scroll;
    overflow-x: hidden;
}

ion-item:not(.native):not([native])::part(native) {
    --padding-start: 10px;
}

.status-badge{
    &.mini {
        padding: 6px;
        font-size: 12px;
        height: 20px;
    }
}

.edit-prayer-alert, .edit-text-dialog{
    --max-width: 90%;
    --min-width: 90%;
    padding-bottom: 20px;
    &.bottom {
        align-items: flex-end;
    }
}

.alert-radio-group {
    .alert-radio-label {
        white-space: normal;
    }
}

.ion-label{
    font-size: 14px;
}

.input {
    width: 100%;
    background-color: transparent;
    --background: transparent;
    margin-bottom: 15px;

    .item-native {
        --background: transparent;
    }

    ion-icon{
        margin-top: auto;
        margin-bottom: auto;
    }
    ion-label{
        display: block;
        font-size: 14px;
        padding-left: 10px;
        margin-bottom: auto;
        margin-top: auto;
        &+ ion-item {
            margin-top: 5px;
        }
    }

    ion-input {
        color: var(--ion-color-dark);
        margin-top: 5px;
        border-radius: 10px;
        padding-left: 10px;
        --padding-start: 10px;
        background: var(--ion-color-light);

        &.has-focus {
            &:hover {
                border: solid 2pt var(--ion-color-light-contrast);
            }

            &.input-item {
                --padding-start: 10px;

                &:focus {
                    border: solid 2pt var(--ion-color-light-shade);
                }
            }
        }
    }

    ion-select {
        margin-top: 5px;
        border-radius: 10px;
        padding-left: 10px;
        background: var(--ion-color-light);
        &::part(text) {
            color: var(--ion-color-dark);
        }

        &.has-focus {

            &:hover {
                border: solid 2pt var(--ion-color-light-contrast);

                &:focus {
                    border: solid 2pt var(--ion-color-light-shade);
                }
            }
        }
    }

    ion-textarea {
        &:not([formControlName=comment]) {
            margin-top: 5px;
        }
        // border: solid 2pt black;
        border-radius: 10px;
        padding-left: 10px;
        background: var(--ion-color-light);

        & + ion-button{
            align-self: center !important;
        }

        &.has-focus {
            &:hover {
                border: solid 2pt var(--ion-color-light-contrast);
                background: var(--ion-color-light);

                &:focus {
                    border: solid 2pt var(--ion-color-light-shade);
                }
            }
        }
    }

    ion-datetime-button {
        --padding-end: 20px;
        border-radius: 10px;
        --background: var(--ion-color-light);
        background: var(--ion-color-light);
        width: 70%;

        &::part(native) {
            background: none;
        }
    }

    ion-item ion-input {
        --padding-start: 10px;
    }


    ion-item {
        --background: var(--ion-color-light) !important;
        --ion-item-background: transparent;
        --padding-start: 0px;
        // --border-bottom-width: 1px var(--black);
        --padding-top: 0px;
        --min-height: 40px;
        --border-radius: 10px;
        margin-bottom: 5px;
        border-radius: 10px;
        --border-color: transparent;
        --border-style: none;

        --highlight-color-focused: none;
        --highlight-color-valid: none;
        --highlight-color-invalid: none;

        &.input-item{
            --background: none !important;
            font-size: 14px;
            --inner-padding-end: 0px !important;
            &::part(native){
                padding-left: 0px;
            }
            &.item-inner{
                border-style: none !important;
                padding-inline-end: 0px;
            }
            ion-select{
                padding: 10px
            }
        }

        &::part(native){
            padding: 0px;
        }
        &:not(.native):not([native])::part(native){
            --padding-start: 10px;
        }

        &.item-inner{
            border-style: none !important;
        }

        ion-icon:not([name^=caret]) {
            font-size: 20px;
        }

        ion-input {
            font-size: 14px;
            // border-bottom: 0;
            --background: none !important;
            --padding-start: 10px !important;
        }

        .item-has-focus {
            color: black !important;
        }

        ion-label {
            font-size: 14px !important;
            padding-left: 10px;
            margin-bottom: 12px;
            margin-top: auto;
        }

        ion-radio{
            margin-inline: 0px 0px;
        }

        ion-popover {
            width: 90%;
        }

    }

    ion-toggle {
        // width:50px;
        height: 20px;

        --background-checked: var(--ion-color-tertiary);
        --handle-background-checked: var(--white);

        --handle-width: calc(20px - (2px * 2));
    }

}

.swiper-slide {
    .media {
        width: 100%;

        img {
            object-fit: cover;
            object-position: center;
            margin-bottom: -6px;
        }

        video {
            margin-bottom: -6px;
        }
    }
}

ion-searchbar input.input {
    border: solid 2pt #666;
}

ion-input.ng-touched.ng-invalid, ion-select.ng-touched.ng-invalid, ion-textarea.ng-touched.ng-invalid{
    border: thin solid red;
}

.input-container {
    margin: 20px 0px 20px 0px;
    font-family: $font-inter;
    font-weight: 300;
}

.input-container select {
    width: 100%;
    padding: 10px 0px 10px 10px;
    border-radius: var(--border-radius);
    border: 2pt solid;
}

.input-error-message {
    border: solid 2pt $danger;
}

.add-media-button {
    background-color: #d3d2d2;
    border-radius: 10px;
    border: solid 2pt var(--black);
    padding: 0px 5px;
    font-size: 12px;

    &:active {
        border: solid 1pt var(--grey);
    }

    ion-icon {
        font-size: 12px;
    }

    img {
        width: 100%
    }
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.footer-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
    background: none;
}

@media screen and (max-height: 710px) {
    // .footer-container{
    //     position: relative;
    //     margin: 10px auto;
    // }
}

.footer-container a {
    text-align: center;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.font-inter {
    font-family: $font-inter;
    font-weight: 300;
}

.font-w-500 {
    font-weight: 500;
}

.font-s-xs {
    font-size: 8px;
}

.font-s-sm {
    font-size: 12px;
}

.font-s-m {
    font-size: 15px;
}

.font-s-10 {
    font-size: 10px;
}

.font-s-20 {
    font-size: 20px;
}

.font-s-25 {
    font-size: 25px;
}

.font-s-40 {
    font-size: 40px;
}

.font-c-white {
    color: $white;
}

.center {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.right {
    text-align: right;
    align-items: flex-end;
}



.no-wrap {
    text-overflow: clip !important;
    white-space: normal !important;
}

.space {
    justify-content: space-between;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.m-auto {
    margin: auto;
}

.mr-20 {
    margin-right: 20px;
}

.mr-40 {
    margin-right: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.pt-0 {
    --padding-top: 0px;
    padding-top: 0px;
}

.pt-10 {
    --padding-top: 10px;
    padding-top: 10px;
}

.pb-0 {
    --padding-bottom: 0px;
    padding-bottom: 0px;
}

.pb-10 {
    --padding-bottom: 10px;
    padding-bottom: 10px;
}

.pb-20 {
    --padding-bottom: 20px;
    padding-bottom: 20px;
}

.pb-70 {
    --padding-bottom: 70px;
    padding-bottom: 70px;
}

.pr-10 {
    --padding-end: 10px;
    padding-right: 10px;
}

.pr-20 {
    --padding-end: 20px;
    padding-right: 20px;
}

.pr-40 {
    --padding-end: 40px;
    padding-right: 40px;
}
.pl-0 {
    --padding-start:0px;
    padding-left: 0px;
}
.pl-10 {
    --padding-start:10px;
    padding-left: 10px;
}

.pl-20 {
    --padding-start: 20px;
    padding-left: 20px;
}

.pl-30 {
    --padding-start: 30px;
    padding-left: 30px;
}

.br-10 {
    --border-radius: 10px;
    border-radius: 10px;
}

.br-round {
    --border-radius: 50%;
    border-radius: 50%;
}

.border-t-l-0 {
    border-top-left-radius: 0px;
}

.border-t-r-0 {
    border-top-right-radius: 0px;
}

.border-b-l-0 {
    border-bottom-left-radius: 0px;
}

.border-b-r-0 {
    border-bottom-right-radius: 0px;
}

.two-thirds {
    flex-basis: 25%;
}

.one-thirds {
    flex-basis: 40%;
}

.w-full {
    width: 100%;
}

.w-quater {
    width: 25%;
}

.h-100 {
    height: 100px;
}

//line
.line {
    flex: 1;
    border-bottom: 1px solid #eeeeee54;
    margin-left: 20px;
    margin-right: 20px;
}

//separator
.separator {
    display: flex;
    align-items: center;
    text-align: center;

    color: $grey;
    font-size: 12px;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--grey);
    margin-left: 20px;
    margin-right: 20px;
}

.separator:not(:empty)::before {
    margin-right: 1em;
}

.separator:not(:empty)::after {
    margin-left: 1em;
}



//-----------------------------login page
.profile {
    width: $logo-width;
    height: $logo-width;
    background-color: $light;
    border-radius: 50%;
}

.logo {
    position: absolute;
    left: calc(50% - $logo-width/2);
    top: calc(100% - $logo-width/2);
}

.forgot-password {
    color: $black;
    text-align: right;
    margin: 10px 0px 20px;
}

@media (prefers-color-scheme: dark) {
    .forgot-password {
        color: $white;
    }
}

.main-button {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: var(--border-radius);
    color: $white;
    font-size: 18px;
}

//--------------------------------------church link
ion-icon.icon-only {
    font-size: 40px;
    --ionicon-stroke-width: 16px;
}

.action-sheet-button {
    color: $black !important;
}

ion-searchbar.search {
    --background: transparent;
    border: solid 2pt #666;
    border-radius: $border-radius;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
}

ion-fab-button.add-church {
    --background: transparent;
    --background-activated: transparent;
    --color: var(--black);
}

//--------------------------------------create profile
.hideFileInput {
    position: absolute;
    visibility: hidden;
    top: 20px;
}

.profile-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    display: block;
    overflow: hidden;
}

.profile-img img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

ion-badge.profile-badge {
    position: absolute;
    margin-left: 65px;
    margin-top: 15px;
    border-radius: 50px;
}

//form step component
.step-container {
    background: none;
    box-shadow: none;
}

.step {
    width: 40px;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #aaa;
    color: #000;
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;
}

//--------------------------------------create funds

.media-create-button {
    background-color: #d3d2d2;
    border-radius: 10px;
    padding: 25px 30px;
    margin: 20px 10px;

    &:active {
        border: solid 1pt black;
    }

    ion-icon {
        font-size: 30px;
    }

    img {
        width: 100%
    }
}

.avatar-container {
    .avatar-content{
        max-width: 100% !important;
        max-height: 100% !important;
    }
}

.media-added-button {
    background-color: none;
    border: solid 1pt #d3d2d2;
    border-radius: 10px;

    padding: 20px 5px;
    margin: 20px 5px;
    max-width: 100px;
    justify-content: center;

    &:active {
        border: solid 1pt black;
    }

    img {
        width: 100%
    }
}

.fund-details {
    ion-item {
        --padding-start: 0px;

        ion-avatar {
            width: 40px;
            height: 40px;
        }

        ion-label {
            background-color: var(--light-grey);
            border-radius: 10px;
            font-size: 17px;
        }

        div {
            width: 100%;
            height: 35px;
            background-color: var(--light-grey);
            border-radius: 10px;

            .fund-fill {
                background-color: var(--ion-color-primary);
                padding: 9px;
            }
        }
    }
}

//expanding contents
.details {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.show {
    white-space: unset;
    height: auto;
}

.status-text {
    border-width: 1px;
    margin-top: 4px;
    border-style: solid;
    border-radius: 25px;
    margin-left: 8px;
    padding-inline: 10px;

    small,
    span {
        display: block;
    }
}

//accordion
ion-accordion-group {
    ion-accordion {
        .ion-accordion-toggle-icon {
            display: none;
        }
        &:not(.set-toggler-margin) 
        .ion-accordion-toggle-icon {
            margin-right: 0;
        }
    }

    &.shepherd-news-manage-expand {
        ion-accordion {
            .ion-accordion-toggle-icon {
                display: block;
                opacity: .5;
                font-size: 18px !important;
            }
        }
    }
}

ion-content.profile-detail {
    ion-accordion-group {
        ion-accordion {
            .ion-accordion-toggle-icon {
                display: block;
                font-size: 14px;
                color: gray;
            }
        }
    }
}



@media screen and (max-width: 290px) {
    .step {
        width: 30px;
        height: 30px;
    }
}

.active {
    background-color: #B395B8;
    color: #fff;
}

.active-ca-step {
    background-color: #575366;
    color: #fff;
}

ion-text.step-circle {
    margin: auto;
}

.bar {
    margin-left: 0.5%;
    width: 99%;
    top: 40%;
    position: absolute;
    border: solid 3pt white;
    z-index: 0;
}

// Fix switch church modal
.switch-church-modal {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 50%;

    // background-color: #e6e6e6;
    ion-avatar {
        display: inline;

        img {
            width: 70px;
            height: 70px;
        }
    }

    &.church-selected {
        border-bottom: solid 2pt #866197;
    }
}

@media (prefers-color-scheme: dark) {
    ion-content {
        --background: var(--dark);
    }

    .bg-white {
        background-color: $dark;
        --background: var(--dark);
    }

    .header {
        --color: var(--white);

        ion-toolbar {
            ion-title {
                --color: var(--white);
            }

            ion-back-button {
                --color: var(--white);
            }
        }
    }

    .profile-header {
        --color: var(--white);

        ion-toolbar {
            ion-title {
                --color: var(--white);
            }

            ion-back-button {
                --color: var(--white);
            }
        }
    }

    .header-segment {
        .segment-button-checked {
            color: var(--white);
        }
    }

    .reaction-segment {
        ion-segment-button {
            --indicator-color: var(--white);
            ion-icon{
                font-size: 20px;
            }
        }

        .segment-button-checked {
            color: var(--white);
        }
    }

    .action-sheet-button {
        color: var(--white) !important;
    }

    // Graph color fix
    .apexcharts-canvas {
        foreignObject {
            span {
                color: var(--ion-color-dark) !important;
            }
        }
    }

    .ml-9 {
        margin-left: 9px;
    }

    .ml-20 {
        margin-left: 20px;
    }
}

mark{
    padding-inline: 6px;
    padding-block: 3px;
    border-radius: 5px;
    font-weight: 900;
    background-color: cyan;
}

.flag{
    filter: drop-shadow(0px 0px 2px var(--ion-color-dark, black));
    fill: black;
}

ngx-avatars {
    text-align: -webkit-center;
}

.sheep-list-items ion-label {
    margin: 0px 8px 0px 0px;
}

.spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.bold {
    font-weight: bold;
}

// hide these element if keyboard is open
html.keyboard-open{
    ion-footer{
        &.copy-right-footer{
            display: none;
        }
        &.footer-inside{
            display: none;
        }
    }
    ion-tabs{
        ion-fab{
            display: none;
        }
    }
    ion-tab-bar{
        display: none;
    }
}

.delete-account {
    margin-top: 70px;
}

.alert-wrapper > .alert-button-group > .do-not-delete-account {
    background-color: green;
    color: white;
    font-weight: bold;
}

.alert-wrapper > .alert-button-group > .delete-account {
    background-color: red;
    color: white;
    font-weight: normal;
}

ion-title{
    &[full],
    &.full{
        padding-inline: 3px !important
    }
}

.paypal-card {
    --background: white;
    background: white;
}

.large-popover{
    --min-width: 288px;
    --max-height: 388px;
}

.modal-alert{
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --height: auto;
    --max-height: 92%;
    --width: 80%;
    --max-width: 96%;
    --overflow: scroll;
}

.terms-cond-btn {
    color: var(--ion-color-secondary) !important;
}

.width-max-content{
    width: max-content !important;
}

.apexcharts-svg{
    background: none!important;
}
.avatar-position{
    float: inline-start; 
    position: absolute;
    top: -44px;
    z-index: 2;
    
    .speech-bubble {
        position: relative;
        background: var(--ion-color-base);
        border-radius: 0.4em;
        height: 20px;
        width: auto;
        padding: 0 5px;
        margin-left: -15px;
        color: $light;
    }
    
    .speech-bubble::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-right-color: var(--ion-color-base);
        border-left: 0;
        border-bottom: 0;
        margin-top: -8px;
        margin-left: -10px;
    }
}

.animated-character-info-modal {
    --max-width: 90%;
    --max-height: auto;
    --height: auto;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    .close-icon{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 2px;
        margin: 2px;
    }
}

.animated-character-info-modal::part(backdrop) {
    background: rgb(0 0 0 / 1);
    opacity: 1;
}
.top-30{
    top: 30px
}

.toggler-icon{
    // color: rgb(203 204 206);
    color: currentColor;
    font-size: 12px;
    margin: 0px;
    opacity: 0.33;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 98; /* Ensure it's below the FAB but above other content */
}

ion-fab-list{
    ion-fab-button{
        &[data-desc] {
            &::after {
                box-shadow: 1px 1px 20px 1px #00000054;
            }
        }
    } 
}

ion-content::part(scroll){
    scrollbar-width: thin !important;
}

/// Scrollbar for admin sidebar
div#collapsed-sidebar {
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px white; 
        border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        background: var(--ion-color-admin);
    }
}
button.days-btn p {
    color: var(--ion-color-dark) !important;
}

.bottom-sheet-modal {
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --height: auto;
    --max-height: 99%;
    --overflow: scroll;
}

// Add some gap for flex
@each $key in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 {
    .gap-#{$key} {
        gap: #{$key}px !important;
        &.em {
            gap: #{$key}em !important;
        }
    }
    .font-size-#{$key} {
        font-size: #{$key}px !important;
        &.em {
            font-size: #{$key}em !important;
        }
    }
    .radius-#{$key} {
        border-radius: #{$key}px !important;
        --border-radius: #{$key}px !important;
        &.em {
            border-radius: #{$key}em !important;
            --border-radius: #{$key}em !important;
        }
    }
    @each $margin in (margin, margin-block, margin-inline, margin-top, margin-bottom, margin-left, margin-right) {
        @if $key == 0 {
            .#{$margin}-auto {
                #{$margin}: auto;
            }
        }
        .#{$margin}-#{$key} {
            #{$margin}: #{$key}px;
            &.em {
                #{$margin}: #{$key}em;
            }
        }
    }
    @each $padding in (padding, padding-block, padding-inline, padding-top, padding-bottom, padding-left, padding-right) {
        @if $key == 0 {
            .#{$padding}-auto {
                #{$padding}: auto;
            }
        }
        .#{$padding}-#{$key} {
            #{$padding}: #{$key}px;
            &.em {
                #{$padding}: #{$key}em;
            }
        }
    }
}